import {getUserSettings, getGlobalShotsCount} from './api'

function getCookie(cName) {
  let name = cName+"="
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
}

export function getUser() {
    let user = getCookie("id")
    
    if(user === undefined){
      console.log("Defaulting user as no cookie set")
      return "1234";
    } else {
      return user
    }
}

export function isDefaultUser() {
    return getUser() == "1234"
}

function isNewToSite() {
  return !localStorage.getItem("visited") && isDefaultUser()
}

export async function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export async function removeCookie(cname) {
  setCookie(cname, "", -100)
}

export async function showDataAlerts(showRecencyAlert = true) {
  if(isDefaultUser()){
    document.getElementById("sampleDataWarning").innerHTML = "<div class='alert alert-warning'><strong>Displaying sample data!</strong> Please login via the <a href='/account.html'>Account page</a> to see your own data.</div>"
  } else {
    if(showRecencyAlert && getCookie("recencyDismiss") != "true"){
      getUserSettings(function(res) {
        if(res.shotRecency > 1){
          document.getElementById("sampleDataWarning").innerHTML = `<div class='alert alert-info alert-dismissible'><a id="recencyAlertDismiss" href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a><strong>Showing stats for the last ${res.shotRecency} shots. </strong> To change your shot recency, visit the <a href='/account.html'>Account page</a>.</div>`;
          document.getElementById("recencyAlertDismiss").addEventListener("click", function(event) {
            setCookie("recencyDismiss", "true", 7)
          });
        }
      })
    }
  }
}

export async function initInfoButtons() {
  //Have to do this because .popover() throws an expcetion in bootstrap
  $('.info-popover').each(function(index, value) {
    try {
        $(this).popover("click");
    }
    catch(err) {
        //Do nothing
    }
  });
}

export async function showWelcomeModal() {
  if(isNewToSite()){
    $.get("components/welcomeModal.html", function( welcomeModalHtml ) {
      let bodyHtml = document.getElementsByTagName("body")[0]
      bodyHtml.innerHTML = bodyHtml.innerHTML + welcomeModalHtml
      $('#welcomeModal').modal('show');
      getGlobalShotsCount((res) => {
        let remainder = res % 100
        let shotsCount = (res - remainder).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+"+";
        document.getElementById("shotCount").innerHTML = shotsCount
      })

      $('#letsGoBtn, .close').on("click", function(e){
        localStorage.setItem('visited', 'true');
      });
  });
  }
}